body {
    margin: 0;
}
html {
    box-sizing: border-box;
    font-size: 112.5%; /*18px*/
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body {
    font-family: 'Open Sans', sans-serif;

    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2b2929;

    font-weight: 400;
    line-height: 1.15;
}

p {
    margin-bottom: 1.15rem;
}

strong {
    font-weight: 600;
}

input {
    font-size: 18px;
}

button {
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
}

input,
textarea {
    font-size: inherit;
    font-family: inherit;
}
